
import { EChartsOption } from 'echarts';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GanttChartGenerator } from '@/models/Charts/ganttChartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DetailedViewEnum } from '@/models/enums/WidgetEnum';
import { LaserGanttData } from '@/models/Charts/chartsData';
import { isEmpty } from '@/utils/misc';
import { tenantsService } from '@/services/tenants.service';

type OeeModeFilter = 'up_time' | 'available_time' | 'work_time';

@Component
export default class OEEIntraday extends Vue {
  @Prop()
  private selectedDeviceIds!: string[];

  @Prop()
  private selectedShifts!: number[];

  @Prop({ required: false })
  private onClose?: () => void;

  private generator!: GanttChartGenerator;
  private data: LaserGanttData[] | null = null;

  private ooeModeFilter: OeeModeFilter = 'up_time';
  private option: EChartsOption = {};

  private modalOpen = true;

  private noData = false;
  private loading = false;

  private async mounted() {
    await this.update();
  }

  @Watch('ooeModeFilter')
  private onFilterChange() {
    this.updateOptions();
  }

  private updateOptions() {
    this.noData = false;
    this.loading = true;
    let acceptedFactors: number[];
    if (this.ooeModeFilter === 'available_time') {
      acceptedFactors = [2, 3, 4];
    } else if (this.ooeModeFilter === 'work_time') {
      acceptedFactors = [4];
    } else {
      acceptedFactors = [1, 2, 3, 4];
    }
    if (!isEmpty(this.data)) {
      const filteredData = this.data!.filter((entry: any) =>
        acceptedFactors.includes(entry.oee_factor),
      );
      this.option = this.generator.updateOptions(filteredData);
    } else {
      this.noData = true;
    }
    this.loading = false;
  }

  private async update() {
    this.generator = new GanttChartGenerator(DetailedViewEnum.IntradayOeeTime, this.tenantIdDh);
    this.data = await this.generator.getData(
      this.selectedDeviceIds,
      this.selectedShifts,
      FilterTimeSpanEnum.Day,
    );
    this.updateOptions();
  }

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }

  private beforeDestroy() {
    this.generator.abort();
  }

  private get tenantIdDh(): number {
    return tenantsService.store.currentIdDh()!;
  }
}
